import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Rob"
              description="Our robotics landing page offers a visually appealing and functional experience. It invites visitors to explore the latest trends and innovations in robotics, presenting information clearly and concisely."
              ghLink="https://github.com/nicolabernal/Rob"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Book store"
              description="Our Bookstore website is a haven for book lovers, designed to provide a seamless browsing and shopping experience. With a clean and intuitive interface, it welcomes visitors to explore a vast collection of literary treasures.

              "
              ghLink="https://github.com/nicolabernal/bookstore"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Reddit clone"
              description="Reddit Clone website is a vibrant online community platform, designed to facilitate discussions, share content, and connect users with similar interests. With a familiar layout and user-friendly interface, it offers a seamless experience for both seasoned Redditors and newcomers alike."
              ghLink="https://github.com/nicolabernal/red"
                           
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
