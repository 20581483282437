import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Nicolas Bernal </span>
            from <span className="purple"> Bogotá, Colombia.</span>
            <br />
            I am a computer systems engineer passionate about front-end development, 
            with an associate degree in graphic design and a love for photography.
            <br />
            My approach blends visual creativity with technical precision to craft impactful digital experiences.
            <br />
            <br />
            I am actively seeking opportunities to apply and expand my skills in a collaborative and innovative environment.
          </p>
        

          <p style={{ color: "#1b8e96a8" }}>
            "Good technology should seem magical."{" "}
          </p>
          <footer className="blockquote-footer"> Jonathan Ive</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
